<template>
  <div
    class="slide-banner"
    :style="{ backgroundImage: `url(${slide.imgBannerUrl})` }"
  >
    <div class="slide-banner__fade" />
    <div class="slide-banner__content">
      <img
        v-if="slide.imgLogoUrl"
        class="slide-banner__logo"
        :src="slide.imgLogoUrl"
      />
      <div class="slide-banner__main">
        <div class="slide-banner__text">
          <h2 v-if="slide.title" class="slide-banner__title">
            {{ slide.title }}
          </h2>
          <p v-if="slide.description" class="slide-banner__subtitle">
            {{ slide.description }}
          </p>
        </div>
        <Button
          tag="a"
          size="regular"
          type="secondary"
          :text="slide.btnTitle"
          :href="slide.url"
          target="_blank"
        />
      </div>
      <img
        v-if="isTrackerUrl"
        class="slide-banner__tmr-pixel"
        :src="impressionCounterLink"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'SlideBanner',
  components: {
    Button,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isTrackerUrl() {
      return this.slide.url.includes('trk.mail.ru');
    },
    impressionCounterLink() {
      if (this.isTrackerUrl) {
        return this.slide.url.replace('/c/', '/i/');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.slide-banner {
  position: relative;
  background-size: cover;
  background-position: center;
}

.slide-banner__fade {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    95deg,
    rgba(0, 0, 0, 0.6) 0,
    rgba(0, 0, 0, 0) 90%
  );
}

.slide-banner__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 360px;
  padding: 60px 16px 16px;

  @include min-laptop() {
    padding: 40px 32px 24px;
    height: 400px;
  }
}

.slide-banner__logo {
  margin-top: auto;
  height: 22px;
  object-fit: contain;
  object-position: left top;
  margin-bottom: 8px;
}

.slide-banner__main {
  display: block;

  @include min-laptop() {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.slide-banner__text {
  margin-bottom: 24px;

  @include min-laptop() {
    margin-bottom: 0;
  }
}

.slide-banner__title {
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 8px;
}

.slide-banner__subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.slide-banner__tmr-pixel {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
}
</style>
