var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main-layout',{class:{ padded: !_vm.slider.length },attrs:{"is-loading":_vm.loadingState.page,"footer-spoiler":true}},[_c('MainCarousel',{attrs:{"slides":_vm.slider}}),_c('div',{staticClass:"pvp-container"},[(_vm.tournamentsIds.length)?[_c('headline',[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('tournaments.recommended')))]),_c('div',{attrs:{"slot":"addon"},slot:"addon"},[_c('pvp-btn',{attrs:{"to":{ name: 'tournaments' },"icon-right":"angle-right","variant":"link"},nativeOn:{"click":function($event){return _vm.tournamentClickHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('tournaments.all'))+" ")])],1)]),_c('div',{staticClass:"tournaments-list"},_vm._l((_vm.tournamentsIds),function(id){return _c('tournament-card',{key:id,attrs:{"id":id}})}),1)]:_vm._e(),_c('section',{staticClass:"games-filter-section"},[_c('GamesFilter',{attrs:{"as-links":true},on:{"input":_vm.goToTournamentsPage,"filter-change":_vm.gamesFilterChangeHandler}})],1),_c('section',{staticClass:"ratings-section"},[_c('headline',[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('ratings.few')))]),_c('div',{attrs:{"slot":"addon"},slot:"addon"},[_c('pvp-btn',{attrs:{"to":{ name: 'ratings' },"icon-right":"angle-right","variant":"link"},on:{"click-native":_vm.moreRatingsClickHandler}},[_vm._v(" "+_vm._s(_vm.$t('ratings.all'))+" ")])],1)]),_c('div',{staticClass:"ratings-table-list"},_vm._l((_vm.ratings),function(rating){return _c('div',{key:rating.id,staticClass:"rating-table-container"},[_c('h4',{staticClass:"rating-table-title"},[_c('BaseLink',{attrs:{"to":{
                name: 'rating',
                params: { id: rating.id, version: rating.version },
              }},on:{"click-native":function($event){return _vm.ratingClickHandler(rating.name)}}},[_vm._v(_vm._s(rating.name))])],1),_c('rating-table',{attrs:{"rating":rating}}),_c('pvp-btn',{staticClass:"about-rating-button",attrs:{"to":{
              name: 'rating',
              params: { id: rating.id, version: rating.version },
            },"block":true,"variant":"secondary"},on:{"click-native":function($event){return _vm.ratingClickHandler(rating.name)}}},[_vm._v(" "+_vm._s(_vm.$t('ratings.more'))+" ")])],1)}),0)],1),_c('headline',[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('news.few')))]),_c('div',{attrs:{"slot":"addon"},slot:"addon"},[_c('pvp-btn',{attrs:{"to":{ name: 'news' },"icon-right":"angle-right","variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('news.all'))+" ")])],1)]),_c('div',{staticClass:"news-list"},_vm._l((_vm.news),function(card){return _c('MainCard',{key:card.id,attrs:{"data":card,"link-to":`/news/${card.id}`}})}),1),_c('headline',[_c('h2',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('video.few')))]),_c('div',{attrs:{"slot":"addon"},slot:"addon"},[_c('pvp-btn',{attrs:{"to":{ name: 'videos' },"icon-right":"angle-right","variant":"link"}},[_vm._v(" "+_vm._s(_vm.$t('video.all'))+" ")])],1)]),_c('div',{staticClass:"videos-list"},_vm._l((_vm.videos),function(video){return _c('MainCard',{key:video.id,attrs:{"data":video,"link-to":`/videos/${video.id}`}})}),1)],2),_c('template',{slot:"seo-text"},_vm._l((_vm.seoText),function(seoTextItem,key){return _c('p',{key:key},[_vm._v(" "+_vm._s(seoTextItem)+" ")])}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }